import React from 'react';
export const Nav30DataSource = {
  wrapper: { className: 'header3 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header3-logo',
    children:
      'https://www.chengwenit.com/img/logo/chwitlogo.png',
  },
  Menu: {
    className: 'header3-menu',
    children: [
      {
        name: 'item0',
        className: 'header3-item',
        children: {
          href: '#Feature5_0',
          children: [{ children: '产品介绍', name: 'text',href: '#Feature5_0', }],
        },
        subItem: [
          {
            name: 'sub0',
            className: 'item-sub',
            children: {
              className: 'item-sub-item',
              href: '#Feature5_0',
              children: [
                {
                  name: 'image0',
                  className: 'item-image',
                  children:
                    'https://www.chengwenit.com/img/icon/liuxiang.svg',
                },
                {
                  name: 'title',
                  className: 'item-title',
                  children: '流向系统',
                },
                {
                  name: 'content',
                  className: 'item-content',
                  children: '流向溯源、销量地图',
                },
              ],
            },
          },
          {
            name: 'sub1',
            className: 'item-sub',
            children: {
              className: 'item-sub-item',
              href: '#Feature5_0',
              children: [
                {
                  name: 'image0',
                  className: 'item-image',
                  children:
                    'https://www.chengwenit.com/img/icon/oms.svg',
                },
                {
                  name: 'title',
                  className: 'item-title',
                  children: '医药OMS',
                },
                {
                  name: 'content',
                  className: 'item-content',
                  children: '全渠道订单统一管理',
                },
              ],
            },
          },
          {
            name: 'sub2',
            className: 'item-sub',
            children: {
              className: 'item-sub-item',
              href: 'https://ji.chengwenit.com',
              children: [
                {
                  name: 'image0',
                  className: 'item-image',
                  children:
                    'https://www.chengwenit.com/img/icon/jobs.svg',
                },
                {
                  name: 'title',
                  className: 'item-title',
                  children: '调度平台',
                },
                {
                  name: 'content',
                  className: 'item-content',
                  children: '异构系统对接统一调度',
                },
              ],
            },
          },
        ],
      },
      {
        name: 'item1',
        className: 'header3-item',
        children: {
          href: '#',
          children: [{ children: '解决方案', name: 'text',href: '#Feature5_0' }],
        },
        subItem: [
          {
            name: 'sub0',
            className: 'item-sub',
            children: {
              className: 'item-sub-item',
              href: '#Feature5_0',
              children: [
                {
                  name: 'image0',
                  className: 'item-image',
                  children:
                    'https://www.chengwenit.com/img/icon/kpi.svg',
                },
                {
                  name: 'title',
                  className: 'item-title',
                  children: 'KPI指标库',
                },
                {
                  name: 'content',
                  className: 'item-content',
                  children: '医药零售经营指标可视化',
                },
              ],
            },
          },
          {
            name: 'sub1',
            className: 'item-sub',
            children: {
              className: 'item-sub-item',
              href: '#Feature5_0',
              children: [
                {
                  name: 'image0',
                  className: 'item-image',
                  children:
                    'https://www.chengwenit.com/img/icon/shuiwu.svg',
                },
                {
                  name: 'title',
                  className: 'item-title',
                  children: '金税接口',
                },
                {
                  name: 'content',
                  className: 'item-content',
                  children: '医药业专属ERP对接金税接口',
                },
              ],
            },
          },
          {
            name: 'sub2',
            className: 'item-sub',
            children: {
              className: 'item-sub-item',
              href: '#Feature5_0',
              children: [
                {
                  name: 'image0',
                  className: 'item-image',
                  children:
                    'https://www.chengwenit.com/img/icon/caiwu.svg',
                },
                {
                  name: 'title',
                  className: 'item-title',
                  children: '财务接口',
                },
                {
                  name: 'content',
                  className: 'item-content',
                  children: '医药行业ERP与金蝶用友凭证接口',
                },
              ],
            },
          },
        ],
      },
      {
        name: 'item2',
        className: 'header3-item',
        children: {
          href: 'http://demo0.chengwenit.com',
          children: [{ children: '演示系统', name: 'text' }],
        },
      },
      {
        name: 'item3',
        className: 'header3-item',
        children: {
          href: 'https://www.chengwenit.com/',
          children: [{ children: '公司官网', name: 'text' }],
        },
      },
    ],
  },
  mobileMenu: { className: 'header3-mobile-menu' },
};
export const Banner50DataSource = {
  wrapper: { className: 'home-page-wrapper banner5' },
  page: { className: 'home-page banner5-page' },
  childWrapper: {
    className: 'banner5-title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>成文科技</p>
          </span>
        ),
        className: 'banner5-title',
      },
      {
        name: 'explain',
        className: 'banner5-explain',
        children: (
          <span>
            <p>致力于医药行业数字化转型</p>
          </span>
        ),
      },
      {
        name: 'content',
        className: 'banner5-content',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <p>医药批发、连锁零售、仓储物流数据解决方案服务商</p>
                </span>
              </span>
            </span>
          </span>
        ),
      },
      {
        name: 'button',
        className: 'banner5-button-wrapper',
        children: {
          href: 'https://p.qiao.baidu.com/cps/chat?siteId=18655299&userId=44582102&siteToken=f49c9366cf4bec832f19bc78a2ec79c0&cp=%E5%8C%BB%E8%8D%AF%E6%95%B0%E5%AD%97%E5%8C%96%E5%AE%98%E7%BD%91&cr=&cw=banner%E8%81%94%E7%B3%BB%E6%88%91%E4%BB%AC',
          className: 'banner5-button',
          type: 'primary',
          children: (
            <span>
              <span>
                <span>
                  <span>
                    <p>在线咨询</p>
                  </span>
                </span>
              </span>
            </span>
          ),
          target: '_blank',
        },
      },
    ],
  },
  image: {
    className: 'banner5-image',
    children: 'https://www.chengwenit.com/img/yaodata.png',
  },
};
export const Content110DataSource = {
  OverPack: {
    className: 'home-page-wrapper content11-wrapper',
    playScale: 0.3,
    always: true,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
     {
       name: 'image',
       children:
         'https://www.chengwenit.com/img/icon/100+.svg',
       className: 'title-image',
     },
     { name: 'title', children: '100+集成模板解决方案', className: 'title-h1' },
     {
       name: 'content',
       children:
         '覆盖医药行业主流B2B2C电商/ERP/WMS/溯源/药监合规等应用场景',
       className: 'title-content',
     },
     {
       name: 'content2',
       children: '点击查看全部应用',
       className: 'title-content',
     },
    ],
  },
  button: {
    className: '',
    children: { a: { className: 'button', href: 'http://doc.chengwenit.com', children: '查看更多' } },
  },
};

export const Feature00DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{ name: 'title', children: '助力成长型医药企业数字化转型' }],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://www.chengwenit.com/img/icon/xinxi.svg',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: '信息化',
            },
            { name: 'content', children: '业务流程的系统化' },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://www.chengwenit.com/img/icon/shuzi.svg',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: '数字化',
            },
            {
              name: 'content',
              children: '挖掘全域数据资产价值或流程，辅助决策',
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://www.chengwenit.com/img/icon/zhineng.svg'
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: '智能化',
            },
            {
              name: 'content',
              children: '智能决策，将人需要付诸的精力和所需的理解减至最低',
            },
          ],
        },
      },
    ],
  },
};
export const Feature60DataSource = {
  wrapper: { className: 'home-page-wrapper feature6-wrapper' },
  OverPack: { className: 'home-page feature6', playScale: 0.3 },
  Carousel: {
    className: 'feature6-content',
    dots: false,
    wrapper: { className: 'feature6-content-wrapper' },
    titleWrapper: {
      className: 'feature6-title-wrapper',
      barWrapper: {
        className: 'feature6-title-bar-wrapper',
        children: { className: 'feature6-title-bar' },
      },
      title: { className: 'feature6-title' },
    },
    children: [
      {
        title: { className: 'feature6-title-text', children: '平台指标' },
        className: 'feature6-item',
        name: 'block0',
        children: [
          {
            md: 8,
            xs: 24,
            className: 'feature6-number-wrapper',
            name: 'child0',
            number: {
              className: 'feature6-number',
              unit: { className: 'feature6-unit', children: '万' },
              toText: true,
              children: '116',
            },
            children: { className: 'feature6-text', children: '主数据条目' },
          },
          {
            md: 8,
            xs: 24,
            className: 'feature6-number-wrapper',
            name: 'child1',
            number: {
              className: 'feature6-number',
              unit: { className: 'feature6-unit', children: '亿' },
              toText: true,
              children: '1.17',
            },
            children: { className: 'feature6-text', children: '处理订单数' },
          },
          {
            md: 8,
            xs: 24,
            className: 'feature6-number-wrapper',
            name: 'child2',
            number: {
              className: 'feature6-number',
              unit: { className: 'feature6-unit', children: '个' },
              toText: true,
              children: '19',
            },
            children: { className: 'feature6-text', children: '对接平台数' },
          },
        ],
      },
      {
        title: { className: 'feature6-title-text', children: '模型指标' },
        className: 'feature6-item',
        name: 'block1',
        children: [
          {
            md: 8,
            xs: 24,
            name: 'child0',
            className: 'feature6-number-wrapper',
            number: {
              className: 'feature6-number',
              unit: { className: 'feature6-unit', children: '个' },
              toText: true,
              children: '7',
            },
            children: { className: 'feature6-text', children: '模型数据' },
          },
          {
            md: 8,
            xs: 24,
            name: 'child1',
            className: 'feature6-number-wrapper',
            number: {
              className: 'feature6-number',
              unit: { className: 'feature6-unit', children: '个' },
              toText: true,
              children: '32',
            },
            children: { className: 'feature6-text', children: '模型应用场景' },
          },
          {
            md: 8,
            xs: 24,
            name: 'child2',
            className: 'feature6-number-wrapper',
            number: {
              className: 'feature6-number',
              unit: { className: 'feature6-unit', children: '亿' },
              toText: true,
              children: '2.10',
            },
            children: { className: 'feature6-text', children: '训练样本数量' },
          },
        ],
      },
    ],
  },
};
export const Feature50DataSource = {
  wrapper: { className: 'home-page-wrapper content7-wrapper' },
  page: { className: 'home-page content7' },
  OverPack: {},
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: '成文医药云提供专业的服务',
        className: 'title-h1',
      },
      { name: 'content', children: '基于成文数据服务平台强大的计算基础资源' },
    ],
  },
  tabsWrapper: { className: 'content7-tabs-wrapper' },
  block: {
    children: [
      {
        name: 'block0',
        tag: {
          className: 'content7-tag',
          text: { children: '数据大屏', className: 'content7-tag-name' },
          icon: { children: 'mobile' },
        },
        content: {
          className: 'content7-content',
          text: {
            className: 'content7-text',
            md: 14,
            xs: 24,
            children: (
              <span>
                <h3>异构数据源整合</h3>
                <p>
                同一个大屏看板可以轻松整合ERP/OA/WMS等多业务系统的数据，打破信息孤岛，进行综合展示分析，让决策更清晰。
                </p>
                <br />
                <h3>海量可视化图表</h3>
                <p>
                  超过多可视化图表类型，包括柱形图、折线图、雷达图、散点图和词云图等，为您展示多样数据信息提供全面支持。
                </p>
                <br />
                <h3>
                多屏自适应
                </h3>仅需设计一次模板，即可在手机、平板、PC、大屏等多种设备上自适应展示，帮助您随时随地掌握企业数据。
              </span>
            ),
          },
          img: {
            className: 'content7-img',
            children:
            'https://www.chengwenit.com/img/prod/prod0.png',
            md: 10,
            xs: 24,
          },
        },
      },
      {
        name: 'block1',
        tag: {
          className: 'content7-tag',
          icon: { children: 'tablet' },
          text: { className: 'content7-tag-name', children: '系统集成' },
        },
        content: {
          className: 'content7-content',
          text: {
            className: 'content7-text',
            md: 14,
            xs: 24,
            children: (
              <span>
                <h3>OMS集成</h3>
                <p>
                多平台订单统一管理，全渠道库存共享，实时库存更新同步、定制锁库策略避免超卖前提下最大化销售库存。
                </p>
                <br />
                <h3>业务集成</h3>
                <p>
                支持货品、客商主数据同步集成，支持证照、经营范围、促销策略、首营审批一键开户集成WMS订单拣货、复核、物流等实时同步。
                </p>
                <br />
                <h3>
                  自助开票
                </h3>对接电子发票平台，支持各电商平台客户在订单中自助申请开具电子发票。
              </span>
            ),
          },
          img: {
            className: 'content7-img',
            md: 10,
            xs: 24,
            children:
              'https://www.chengwenit.com/img/prod/prod1.png',
          },
        },
      },
      {
        name: 'block2',
        tag: {
          className: 'content7-tag',
          text: { children: '数字化门店', className: 'content7-tag-name' },
          icon: { children: 'laptop' },
        },
        content: {
          className: 'content7-content',
          text: {
            className: 'content7-text',
            md: 14,
            xs: 24,
            children: (
              <span>
                <h3>线上线下一体</h3>
                <p>
                集成慢病、健康顾问、BI商业智能、商城营销平台、客户增值服务平台、融合线上O2O基于地理位置辐射周边人群。
                </p>
                <br />
                <h3>门店KPI可视化</h3>
                <p>
                集成内部系统以及门店iot数据,来店数、客单价、转化率等门店KPI指标实施可视化查询。
                </p>
                <br />
                <h3>
                  积分商城
                </h3>与门店会员体系相结合，满足多场景积分运营需求，提升用户活跃，增加品牌忠诚度。
              </span>
            ),
          },
          img: {
            className: 'content7-img',
            md: 10,
            xs: 24,
            children:
              'https://www.chengwenit.com/img/prod/prod2.gif',
          },
        },
      },
      {
        name: 'block3',
        tag: {
          className: 'content7-tag',
          text: { children: '医药流向', className: 'content7-tag-name' },
          icon: { children: 'mobile' },
        },
        content: {
          className: 'content7-content',
          text: {
            className: 'content7-text',
            md: 14,
            xs: 24,
            children: (
              <span>
                <h3>主数据管理</h3>
                <p>
                规范企业基础主数据：人员、货品、客户
                </p>
                <br />
                <h3>数据处理</h3>
                <p>
                  自动化采集、上传预处理、数据质量检查、清晰、转换。
                </p>
                <br />
                <h3>
                数据修正
                </h3>支持权限控制，人工修正流向数据。
              </span>
            ),
          },
          img: {
            className: 'content7-img',
            md: 10,
            xs: 24,
            children:
              'https://www.chengwenit.com/img/prod/prod3.png',
          },
        },
      },
    ],
  },
};
export const Content50DataSource = {
  wrapper: { className: 'home-page-wrapper content5-wrapper' },
  page: { className: 'home-page content5' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'title', children: '客户案例', className: 'title-h1' },
      {
        name: 'content',
        className: 'title-content',
        children: '专业的服务，始终如一',
      },
    ],
  },
  block: {
    className: 'content5-img-wrapper',
    gutter: 16,
    children: [

      {
        name: 'block0',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://www.chengwenit.com/img/case/huajian.png',
          },
          content: { children: '海南华健药业ERP数据集成平台' },
        },
      },
      {
        name: 'block1',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://www.chengwenit.com/img/case/hengxiang.png',
          },
          content: { children: '广东恒祥医药ERP&WMS集成' },
        },
      },
      {
        name: 'block2',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://www.chengwenit.com/img/case/wanbang.png',
          },
          content: { children: '通用万邦药业数据服务' },
        },
      },
      {
        name: 'block3',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://www.chengwenit.com/img/case/bangjian.png',
          },
          content: { children: '广东邦键医药数据服务' },
        },
      },
      {
        name: 'block4',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://www.chengwenit.com/img/case/longteng.png',
          },
          content: { children: '隆腾医疗CRM+呼叫系统' },
        },
      },
      {
        name: 'block5',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://www.chengwenit.com/img/case/mayinglong.png',
          },
          content: { children: '马应龙药业报表数据服务' },
        },
      },
      {
        name: 'block6',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://www.chengwenit.com/img/case/xingsha.png',
          },
          content: { children: '国药控股星鲨制药报表数据服务' },
        },
      },
      {
        name: 'block7',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://www.chengwenit.com/img/case/lanzhoudaxue.svg',
          },
          content: { children: '兰州大学医学实验室数据服务' },
        },
      },
    ],
  },
};
export const Feature80DataSource = {
  wrapper: { className: 'home-page-wrapper feature8-wrapper' },
  page: { className: 'home-page feature8' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'feature8-title-wrapper',
    children: [
      {
        name: 'title',
        className: 'feature8-title-h1',
        children: (
          <span>
            <p>服务流程</p>
          </span>
        ),
      },
      {
        name: 'content',
        className: 'feature8-title-content',
        children: '流程简单清晰，快速响应需求',
      },
    ],
  },
  childWrapper: {
    className: 'feature8-button-wrapper',
    children: [
      {
        name: 'button',
        className: 'feature8-button',
        children: {
          href: 'https://wj.qq.com/s2/10673061/ecc3/',
          children: '发起需求',
        },
      },
    ],
  },
  Carousel: {
    dots: false,
    className: 'feature8-carousel',
    wrapper: { className: 'feature8-block-wrapper' },
    children: {
      className: 'feature8-block',
      titleWrapper: {
        className: 'feature8-carousel-title-wrapper',
        title: { className: 'feature8-carousel-title' },
      },
      children: [
        {
          name: 'block0',
          className: 'feature8-block-row',
          gutter: 120,
          title: {
            className: 'feature8-carousel-title-block',
            children: (
              <span>
                <span>
                  <span>
                    <p>开发服务流程</p>
                  </span>
                </span>
              </span>
            ),
          },
          children: [
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child0',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://www.chengwenit.com/img/service/jiantou.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://www.chengwenit.com/img/service/diaoyan.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: (
                      <span>
                        <p>需求调研</p>
                      </span>
                    ),
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children: '专业医药行业业务顾问，调研和分析需求，分析可行性',
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child1',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://www.chengwenit.com/img/service/jiantou.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://www.chengwenit.com/img/service/lantu.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: (
                      <span>
                        <p>蓝图设计</p>
                      </span>
                    ),
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children:
                      '接收需求，转化为设计文档，作为开发与业务人员直接的桥梁',
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child2',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://www.chengwenit.com/img/service/jiantou.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://www.chengwenit.com/img/service/shishi.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: (
                      <span>
                        <span>
                          <p>实施开发</p>
                        </span>
                      </span>
                    ),
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children:
                      '实施培训、开发编码、测试、部署',
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child3',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://www.chengwenit.com/img/service/jiantou.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://www.chengwenit.com/img/service/yunwei.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: (
                      <span>
                        <p>上线维护</p>
                      </span>
                    ),
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children:
                      '切替上线后进行维护，保障成功应用',
                  },
                ],
              },
            },
          ],
        },
        {
          name: 'block1',
          className: 'feature8-block-row',
          gutter: 120,
          title: {
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>运维服务流程</p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            ),
            className: 'feature8-carousel-title-block',
          },
          children: [
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child0',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://www.chengwenit.com/img/service/jiantou.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://www.chengwenit.com/img/service/faqi.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: (
                      <span>
                        <span>
                          <p>服务工单发起</p>
                        </span>
                      </span>
                    ),
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children: '由用户或者成文实施顾问发起服务请求',
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child1',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://www.chengwenit.com/img/service/jiantou.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://www.chengwenit.com/img/service/yixian.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: (
                      <span>
                        <p>一线客服</p>
                      </span>
                    ),
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children:
                      '一线客服人员服务台接单，处理基本操作和咨询问题',
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child2',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://www.chengwenit.com/img/service/jiantou.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://www.chengwenit.com/img/service/erxian.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: (
                      <span>
                        <p>二线技术支持</p>
                      </span>
                    ),
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children:
                      '一线客服解决不了技术问题，如系统配置、运行环境故障排查等，有技术支持人员排查和处理',
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child3',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://www.chengwenit.com/img/service/jiantou.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://www.chengwenit.com/img/service/sanxian.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: (
                      <span>
                        <p>三线研发</p>
                      </span>
                    ),
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children:
                      '技术支持无法解决的程序BUG，性能调优、架构建议有研发人员进行支持',
                  },
                ],
              },
            },
          ],
        },
      ],
    },
  },
};
export const Footer10DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block1',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: '推荐' },
        childWrapper: {
          children: [
            {
              name: 'link0',
              href: 'https://cloud.chengwenit.com',
              children: (
                <span>
                  <span>
                    <p>企业上云</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'link1',
              href: 'http://ji.chengwenit.com',
              children: (
                <span>
                  <span>
                    <span>
                      <p>调度平台</p>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'link2',
              href: 'http://ji.chengwenit.com',
              children: (
                <span>
                  <p>数据集成</p>
                </span>
              ),
            },
            {
              name: 'link3',
              href: 'https://mp.weixin.qq.com/s?__biz=MzU4Nzc1ODMxOQ==&mid=2247484143&idx=1&sn=03fad7c1c0d415a47c62a5821a41348f&chksm=fde66d6fca91e47970a26c6aa8f90e6bd3cffc902248a739e96d7e8dfeaa6020b9156ff0ca5c#rd',
              children: (
                <span>
                  <p>
                    <span>数据安全</span>
                    <br />
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block~kj8d6orbk2',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          children: (
            <span>
              <span>
                <span>
                  <p>联系我们</p>
                </span>
              </span>
            </span>
          ),
        },
        childWrapper: {
          children: [
            {
              name: 'link0',
              href: 'https://p.qiao.baidu.com/cps/chat?siteId=18655299&userId=44582102&siteToken=f49c9366cf4bec832f19bc78a2ec79c0&cp=%E5%8C%BB%E8%8D%AF%E6%95%B0%E5%AD%97%E5%8C%96%E5%AE%98%E7%BD%91&cr=&cw=%E5%9C%A8%E7%BA%BF%E5%AE%A2%E6%9C%8D',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>在线客服</p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'link1',
              href: 'https://p.qiao.baidu.com/cps/chat?siteId=18655299&userId=44582102&siteToken=f49c9366cf4bec832f19bc78a2ec79c0&cp=%E5%8C%BB%E8%8D%AF%E6%95%B0%E5%AD%97%E5%8C%96%E5%AE%98%E7%BD%91&cr=&cw=%E7%94%B5%E8%AF%9D%E5%8F%B7%E7%A0%81',
              children: (
                <span>
                  <span>
                    <p>Tel:&nbsp; 18617332875</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'link2',
              href: 'https://p.qiao.baidu.com/cps/chat?siteId=18655299&userId=44582102&siteToken=f49c9366cf4bec832f19bc78a2ec79c0&cp=%E5%8C%BB%E8%8D%AF%E6%95%B0%E5%AD%97%E5%8C%96%E5%AE%98%E7%BD%91&cr=&cw=%E5%BE%AE%E4%BF%A1',
              children: (
                <span>
                  <span>
                    <p>Wechat:&nbsp; xiangyun_it</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'link3',
              href: 'http://blog.chengwenit.com',
              children: (
                <span>
                  <p>Blog</p>
                </span>
              ),
              target: '_blank',
            },
          ],
        },
      },
      {
        name: 'block0',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          className: 'logo',
          children: 'https://www.chengwenit.com/img/logo/gzhm.jpg',
        },
        childWrapper: {
          className: 'slogan',
          children: [
            {
              name: 'content0',
              children: (
                <span>
                  <p>成文科技公众号</p>
                </span>
              ),
              className: 'kj8e0fiwri-editor_css',
            },
          ],
        },
      },
      {
        name: 'block~kj8d34w57fd',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          className: 'logo',
          children: 'https://www.chengwenit.com/img/logo/wxkf.png',
        },
        childWrapper: {
          className: 'slogan',
          children: [
            {
              name: 'content0',
              children: (
                <span>
                  <p>成文科技在线客服</p>
                </span>
              ),
              className: 'kj8e0mn7thk-editor_css',
            },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        <span>
          <span>
            <span>
              Copyright © 广州成文数字科技有限公司 | 2023  &nbsp; &nbsp;
              <a href="http://beian.miit.gov.cn">粤ICP备17077704号</a> <br />
            </span>
          </span>
        </span>
      </span>
    ),
  },
}


